import React from "react";
import { useNavigate } from "react-router-dom";
import SectionTitle from "../Title";
import "./style.scss";

const ServiceArea = ({ className, title, subTitle, services }) => {
  const navigate = useNavigate();

  return (
    <div className={className}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <SectionTitle title={title} subTitle={subTitle} />
          </div>
          {services.map((service, index) => (
            <div
              onClick={() => {
                if (service?.url) {
                  navigate(service.url);
                }
              }}
              key={index}
              className="col-lg-4 col-md-6"
              style={{
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
            >
              <div className="serviceWrap">
                <div className="serviceIcon">
                  <i className={`fi ${service.icon}`}></i>
                </div>
                <div className="serviceContent">
                  <h3>{service.title}</h3>
                  <p>{service.content}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default ServiceArea;
