import React, { Fragment } from "react";
import HeaderBotton from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import Breadcumb from "../../../components/Breadcumb";
import NewsLetter from "../../../components/Newsletter";
import FooterArea from "../../../components/FooterArea";
import Form from "../../../components/Form";
// images
import breadcumb from "../../../images/breadcumb/1.jpg";

import "./style.scss";
import { ADDRESS, PHONE } from "../../../utils/helpers";

const breadcumbMenu = [{ name: "Home", route: "/" }, { name: "Contact" }];

const ContactPage = () => {
  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
      </header>
      <Breadcumb
        className="breadcumbArea"
        title="Contact"
        breadcumbMenu={breadcumbMenu}
        background={
          "https://saracenssolicitors.co.uk/wp-content/uploads/2020/08/saracens-solicitors-london-banner.jpg"
        }
      />

      <div className="contactusPageArea">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="contactUsInfo">
                <h3>Contact Us</h3>
                <p>Get in touch with us today</p>
                <h4>Address</h4>
                <span>{ADDRESS}</span>
                <h4>Phone</h4>
                <span>{PHONE}</span>
                <h4>Email</h4>
                <span>info@ajrsolicitors.com</span>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="contactUSForm">
                <h3>Contact Form</h3>
                <Form addressInfo={true} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <NewsLetter className="newsLetterArea" />
      <FooterArea />
    </Fragment>
  );
};
export default ContactPage;
