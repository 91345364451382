import React, { Fragment, useMemo } from "react";
import HeaderBotton from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import Breadcumb from "../../../components/Breadcumb";
import Service from "../../../components/Service";
import ServiceArea from "../../../components/ServiceArea";
import Testmonial from "../../../components/Testmonial";
import PricingTable from "../../../components/PricingTable";
import NewsLetter from "../../../components/Newsletter";
import FooterArea from "../../../components/FooterArea";
// images
import breadcumb from "../../../images/breadcumb/1.jpg";

import "./style.scss";
import { PRACTICES } from "../../../utils/helpers";

const aboutText = [
  {
    text: "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at ,",
  },
  {
    text: 'and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum',
  },
];

const services = [
  {
    icon: "flaticon-parents",
    title: "Family Law",
    content: "There are many variations of passages of Lorem ",
  },
  {
    icon: "flaticon-wounded",
    title: "Personal Injury",
    content: "There are many variations of passages of Lorem ",
  },
  {
    icon: "flaticon-employee",
    title: "Business Law",
    content: "There are many variations of passages of Lorem ",
  },
  {
    icon: "flaticon-thief",
    title: "Criminal Law",
    content: "There are many variations of passages of Lorem ",
  },
  {
    icon: "flaticon-university-graduate-hat",
    title: "Education Law",
    content: "There are many variations of passages of Lorem ",
  },
  {
    icon: "flaticon-house",
    title: "Real Estate Law",
    content: "There are many variations of passages of Lorem ",
  },
];

const breadcumbMenu = [{ name: "Home", route: "/" }, { name: "Practice area" }];

const PracticePage = () => {
  const ss = useMemo(
    () =>
      PRACTICES.map((p) => ({
        icon: "flaticon-employee",
        title: p.title,
        content: "",
        url: `/practice-details/${p.url}`,
      })),
    []
  );

  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
      </header>
      <Breadcumb
        className="breadcumbArea"
        title="Practice Area"
        breadcumbMenu={breadcumbMenu}
        background={
          "https://saracenssolicitors.co.uk/wp-content/uploads/2020/08/saracens-solicitors-london-banner.jpg"
        }
      />
      <Service className="serviceArea mt-0" />
      <ServiceArea
        className="ourServiceArea ourServiceAreaStyleThree bgFFF"
        title="How Can We Help You"
        subTitle="Area Of Practice"
        services={ss}
      />
      {/* <PricingTable 
                className="pricingTableArea"
                title="Pricing Table"
                subTitle="Our Pricing Plan"
            /> */}
      <Testmonial className="testmonialArea pt100" />
      <NewsLetter className="newsLetterArea" />
      <FooterArea />
    </Fragment>
  );
};
export default PracticePage;
