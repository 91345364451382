// export const PHONE = "07280637";
export const PHONE = "+44 121 318 6409";
export const ADDRESS = "41 Church Street, Birmingham, England, B3 2RT";
export const PRACTICES = [
  {
    url: "family-law",
    title: "Family Law",
    sections: [
      {
        subtitle: "Prenuptial Agreement",
        text: "A prenuptial agreement is a contract that is entered into by couples before marriage which determines the division of property and assets including spousal support in the event of a divorce. We will provide you accurate advice with regards to drafting the agreement.",
      },
      {
        subtitle: "Domestic Violence",
        text: "Our lawyers have experience in dealing with domestic violence and will help you to achieve lasting solutions within a safe and friendly environment. We can protect you by obtaining legal injunctions that prevent further abuse, whether that abuse is physical or psychological.",
      },
      {
        subtitle: "Legal Separation",
        text: "Legal or judicial separation is the legal process by which the court declares that a couple are legally separated. While legal Separation does not dissolve the marriage, it releases the parties from the duty to live together.",
      },
      {
        subtitle: "Annulment",
        text: "An annulment is a legal declaration by the court that the marriage was never valid and reverses the couple’s status to what it was before they entered the marriage. Our specialist team of family lawyers will advise you if annulment is appropriate for you and help you through the process.",
      },
      {
        subtitle: "Divorce",
        text: "Our team of family lawyers will provide guidance in relation to the divorce process, your children and finances. As members of Resolution, we provide clear and constructive answers that cater to your individual needs in the best interests of you and your family.",
      },
      {
        subtitle: "Child Custody",
        text: "In the event of a divorce, our specialised team will advise you of your rights and will help determine issues such as where your child will live, who will make the major decisions for the child and the amount of t§e your children spend with each parent.",
      },
    ],
    image:
      "https://saracenssolicitors.co.uk/wp-content/uploads/2023/04/divorce-family-law-saracens-solicitors.jpg",
  },
  {
    url: "personal-injury",
    title: "Personal Injury",
    sections: [
      {
        subtitle: "Accident Abroad / Holiday Accidents",
        text: "We are experienced at providing advice in a range of areas related to Accident Abroad or Holiday Accidents: Package holidays, overseas road traffic accidents, accidents by air and at sea, injury caused by faulty hotel equipment or accidents that occur inside hotels.",
      },
      {
        subtitle: "Carbon Monoxide Poisoning Claim",
        text: "If you think you have been exposed to Carbon Monoxide, our team of personal injury experts will be able to advise you on your rights to make a claim and take you through the process of how to make your claim in a seamless fashion.",
      },
      {
        subtitle: "Industrial Disease Claims",
        text: "Our experts can help you with matters dealing with industrial deafness, dermatitis contracted at work, occupational asthma, repetitive strain injuries and upper limb disorders and all forms of industrial illness or injuries suffered at work",
      },
      {
        subtitle: "Product Injury Claims",
        text: "In the event that a faulty good or product has caused you injury, our personal injury specialists will guide you through the claim process, even if you have bought the product on the internet, and ensure that you are adequately compensated.",
      },
      {
        subtitle: "Road Traffic Accidents",
        text: "If you have been injured in a road traffic accident, you may be entitled to damages, reimbursement and compensation for any loss you may have suffered. Our expert team will guide you through the entire process, negotiating and making a claim to achieve the best compensation for you.",
      },
      {
        subtitle: "Workplace Accidents",
        text: "If your employers have not complied with various health and safety regulations and you have sustained an injury at work you could be entitled to bring a claim. For example: Falls at Work, Falling on you at Work, Injuries caused by Faulty Equipment, Injuries caused by negligence of other employees",
      },
    ],
    image:
      "https://saracenssolicitors.co.uk/wp-content/uploads/2023/04/personal-injury-saracens-solicitors-london.jpg",
  },
  {
    url: "commercial-law",
    title: "Commercial Law",
    sections: [
      {
        subtitle: "Commercial Law",
        text: "We specialise in advising sole traders to owner-managed companies as well as private and public limited companies on matters relating to - Acquisitions / Sales of Business or Assets, Mergers & Acquisitions, Commercial Contracts, Partnerships & Joint Ventures, Crowdfunding, Confidentiality / Non-Disclosure Agreements, E-commerce, Franchising whether you’re a franchisor or franchisee, Technology / app start ups and platform development, Licensing Law.",
      },
      {
        subtitle: "Company Law",
        text: "We offer a wide range of services related to all aspects of Company Law including - Corporate governance, Directors’ duties, Service contracts, Business incorporation, Shareholders agreements.",
      },
      {
        subtitle: "Corporate Recovery and Insolvency",
        text: "Our advisors are able to offer advice on a variety of options available to businesses including administration, company voluntary arrangements, liquidation, and receivership. We also have the expertise to offer advice and guidance to creditors of an insolvent party.",
      },
      {
        subtitle: "Charities",
        text: "Charities are subject to strict regulatory control and require specialist legal and commercial advice to ensure full compliance. We can help you with - Providing guidance on the Charities Commission’s requirements when setting up a charity, Drafting bespoke constitutions, Advising on the management of the charity, Advising trustees of their duties.",
      },
      {
        subtitle: "Data Protection Law",
        text: "From our data protection team, you will receive expert legal advice in an area of law which is becoming increasingly demanding and complex as each year passes since the advent of the General Data Protection Regulation (GDPR) coming into force in May 2018 (now the UK GDPR since leaving the EU). We act for commercial organisations, sole practitioners and owner-managed companies, as well as not-for-profit and educational establishments.",
      },
      {
        subtitle: "Healthcare Sector",
        text: "We have specialist knowledge in providing advice and guidance to owners, buyers and sellers of dental practices, medical practices, nurseries and nursing homes and can ensure your business is placed in a strong legal position throughout the various stages of its development.",
      },
    ],
    image:
      "https://saracenssolicitors.co.uk/wp-content/uploads/2023/04/commercial-law-saracens-solicitors.jpg",
  },
  {
    url: "real-estate-law",
    title: "Real Estate Law/Conveyancing",
    sections: [
      {
        subtitle: "Auction Property",
        text: "Our conveyancing team can review the legal pack that you obtain from the auctioneers beforehand and provide a report on the property, which will give you all the vital information about the property, permitting you to make an informed decision on whether to buy the property.",
      },
      {
        subtitle: "Buying Property",
        text: "We are committed to providing you with all the vital information about the property you are purchasing and the surrounding areas. We will also advise you in relation to your mortgage, and detail the key terms of your loan and your obligations to the bank.",
      },
      {
        subtitle: "Selling Property",
        text: "We have years of experience in ensuring that a sale proceeds efficiently and easily. We collect the necessary information from you at the outset and create a “sale pack” to go to the purchaser’s solicitors quickly to begin the process..",
      },
      {
        subtitle: "Leasing Property",
        text: "We will assist you with the process, known as enfranchisement, whereby leaseholders are able to extend their lease or they can purchase the freehold interest in a property, thus becoming both tenant and landlord, and having more security over the building’s management and control.",
      },
      {
        subtitle: "Remortgaging",
        text: "If you want to remortgage your property, we will provide you with a straightforward & stress-free service right from receiving your mortgage offer, to redemption of your existing mortgage, and accounting to you the proceeds of your new mortgage, as well as registration at the Land Registry.",
      },
      {
        subtitle: "Shared Ownership",
        text: "We are Specialist Shared Ownership Solicitors, and among the first 100 accredited members of The Law Society’s Conveyancing Quality Scheme. This means we are recognised by the Law Society as providers of high quality conveyancing services.",
      },
      {
        subtitle: "Tenancy Agreements",
        text: "Whether you are a landlord who needs a secure, tailored contract drafted or a tenant who wishes to enter into a tenancy and needs to know your rights, we will advise you fully and draw up a suitable agreement for you.",
      },
    ],
    image:
      "https://saracenssolicitors.co.uk/wp-content/uploads/2023/04/conveyancing-solicitors.jpg",
  },
  {
    url: "corporate-crime-and-risks",
    title: "Corporate Crime & Risks.",
    sections: [
      {
        subtitle: "Commercial Fraud",
        text: "We offer expert advice and representation to businesses and corporate officers in both seeking pursuing and defending claims for commercial fraud. In terms of defendant work our term are well experienced in Defending claims against companies and individuals for fraud by regulatory bodies such as: HM Revenue and Customs, Serious Fraud Office, Financial Services Authority.",
      },
      {
        subtitle: "Money Laundering",
        text: "We have the expertise to advise businesses on how to ensure compliance with The Money Laundering Regulations 2007 (as amended) and also to represent those who are under investigation.",
      },
      {
        subtitle: "VAT, Tax & Duty Fraud",
        text: "Prosecutions and investigations into tax, VAT or duty fraud can result in a number of allegations involving various complex legal areas. Our criminal litigation team will provide you with a clear and concise assessment of your case, along with honest and practical advice on what steps will follow.",
      },
    ],
    image:
      "https://saracenssolicitors.co.uk/wp-content/uploads/2023/04/corporate-crimes-risks-saracens-solicitros.jpg",
  },
  {
    url: "employment-law",
    title: "Employment Law",
    sections: [
      {
        subtitle: "Employment Contract",
        text: "Our employment team will define and break down the terms of a contract whether you are negotiating a contract, seeking a way out of the contract or advise you if you or your employer are in breach of that contract.",
      },
      {
        subtitle: "Compromise Agreement",
        text: "A compromise agreement is a contract in which an employee or former employee accepts compensation in exchange for dropping all claims against an employer. We can determine whether the terms of the agreement are fair, and help negotiate in your interests.",
      },
      {
        subtitle: "Constructive Dismissal",
        text: "When an employer breaches an important term of the employment contract, leaving the employee with no choice but to resign, it is considered a Constructive Dismissal. We offer expert advice on what to do if you find your position at work untenable, and how to be compensated as a result.",
      },
      {
        subtitle: "Redundancy",
        text: "Your employer is required to follow some strict procedures if he intends on making your job redundant. If they have failed to do so, it is likely that you will have a claim for unfair dismissal. In certain circumstances, you could also be entitled to compensation..",
      },
      {
        subtitle: "Discrimination",
        text: "If you are discriminated against at work as a result of your age, gender, sex, race, religion, sexual orientation or for any other reason, our specialised team will advise you on your potential claim and represent you in any Tribunal proceedings",
      },
      {
        subtitle: "Wrongful Termination / Unfair Dismissal",
        text: "If you believe that you have been unfairly dismissed, we can advise you regarding your claim for unfair dismissal, as well as the chances of success, the compensation that could be awarded to you and we can also assist you in proceeding to the Employment Tribunal.",
      },
    ],
    image:
      "https://saracenssolicitors.co.uk/wp-content/uploads/2023/04/employment-saracens-solicitors-london.jpg",
  },
  {
    url: "civil-litigation",
    title: "Civil Litigation",
    sections: [
      {
        subtitle: "Dispute Resolution",
        text: "We provide Dispute Resolution services to clients in the following cases: Consumer Dispute, E-Commerce Dispute, Contractual Dispute, Partnership Dispute, Debt Recovery, Public Law and Judicial Review, Director and Shareholder Dispute.",
      },
      {
        subtitle: "Inheritance, Wills & Probate",
        text: "We handle the following types of cases: Disputed wills based on allegations of fraud, mental capacity, and undue influence, Disputes involving Executors or Trustees, Claims under the Inheritance (Provision for Family and Dependents) Act 1975, Negligently drafted wills and Professional Negligence claims, Disputed Power of Attorney.",
      },
      {
        subtitle: "Insolvency",
        text: "We provide legal advice to clients who find themselves on either side of insolvency, and can assist you with the following insolvency processes: Bankruptcy, Liquidations, Individual and Company Voluntary Arrangements, Administrations, Receiverships.",
      },
      {
        subtitle: "Media & Intellectual Property",
        text: "We advise on all aspects of media law and breaches of it and have in-depth experience of defamation, libel and slander law, We provide legal advice on all aspects of Intellectual Property Law that covers copyright, patents, trademarks, design rights, protection from passing off and protection of confidential information.",
      },
      {
        subtitle: "Negligence",
        text: "Our litigation team can assist with all aspects of negligence claims from establishing a claim and causation to issuing Court proceedings and obtaining the appropriate remedy. We offer legal assistance in cases of: Medical / Clinical Negligence, Professional Negligence.",
      },
      {
        subtitle: "Property",
        text: "Our property litigation team are experienced in Leasehold issues and Freehold issues like: Adverse Possession Claims, Engineering and Building Disputes, International & Domestic Property Litigation, Landlord and Tenant Disputes, Neighbour and Property Disputes.",
      },
    ],
    image:
      "https://saracenssolicitors.co.uk/wp-content/uploads/2023/04/civil-litigation-saracens-solicitors.jpg",
  },
  {
    url: "estate-planning-wills-probate",
    title: "Estate Planning, Wills & Probate.",
    sections: [
      {
        subtitle: "Asset Protection Planning",
        text: "Asset protection planning involves legally safeguarding your assets from your creditors. Our specialised team are able to advise you on different options about how to protect your assets including the use of offshore structures and the setting up of varying trusts.",
      },
      {
        subtitle: "Power of Attorney",
        text: "A power of attorney allows an individual to authorise another to carry out a number of private or commercial matters on their behalf. We help tailor these documents to your specific needs and advise you on any limitations necessary for your own protection.",
      },
      {
        subtitle: "Probate and Estate Administration",
        text: "We assist with the administration of a person’s estate. We also assist executors to obtain probate or acquire letters of administration to allow them to deal with and distribute the estate of the deceased. We also advise if inheritance tax is payable",
      },
      {
        subtitle: "Wills",
        text: "We can draft a comprehensive will on your behalf which will enable you to decide and direct what will happen to your money, property and possessions upon your death. Without a will, the law decides how your assets are divided.",
      },
      {
        subtitle: "Crypto Wills & Trusts",
        text: `If you trade in cryptocurrencies and want them to form part of your will, you need to consider how your current inheritance planning can include this digital form of cash. Without the right protection in place for beneficiaries, the money could remain inaccessible and wrapped up in legal complications for years to come.

        We are experts in will writing and are ideally placed to help you put in place a strong will that incorporates cryptocurrency. From crypto wills to crypto trusts, we ensure your future plans can move forward without complication.`,
      },
    ],
    image:
      "https://saracenssolicitors.co.uk/wp-content/uploads/2023/04/estate-wills-probate-saracens-solicitors.jpg",
  },
  {
    url: "immigration",
    title: "Immigration",
    sections: [
      {
        subtitle: "Entry into the UK",
        text: "From entrepreneur visa to investor visa, from visiting loved ones with a family visa to working under a sponsored skilled worker visa, from being a student under the points based system or seeking a student visitor visa, we can help secure you entry into the U.K.",
      },
      {
        subtitle: "Immigration Appeal",
        text: "If the Home Office refused your application for a visa or for immigration status in the U.K., we can help achieve justice and a fair result on your matter, using the following: Administrative review, Discretionary application / Human Rights appeals, Judicial review, Reconsideration appeal",
      },
      {
        subtitle: "Representative of an Overseas Business (ROB)Visa",
        text: "The ROB visa, also commonly known as the Sole Representative Visa, is a business visa that allows an overseas business to set up either a UK branch or subsidiary. In this post, we will look at the key requirements for the ROB visa and common reasons for refusal.",
      },
      {
        subtitle: "Returning Resident Visa",
        text: "If you have Indefinite Leave to Remain (ILR) and you have been away from the UK for more than two continuous years, you will need a Returning Resident visa to come back to live in the UK.",
      },
      {
        subtitle: "The British National (Overseas) BN(O) Visa",
        text: `The British National (Overseas) visa is a unique visa route that allows BN(O) status holders and their close family members to enter and live in the UK. You can find out more information about the BN(O) route on our Q&A page.`,
      },
      {
        subtitle: "Indefinite Leave to Remain (ILR) – 10-year long resident",
        text: `You can apply for ILR if you have been in the UK lawfully for 10 continuous years. This post sets out the requirements for an ILR application on the basis of 10 years lawful residence. Requirements`,
      },
      {
        subtitle: "Settling in the UK",
        text: `Our award-winning immigration team specialises in helping clients achieve their goal of settling in the UK. We obtain visa extensions, secure permanent rights to stay in the country and give advice on how to obtain citizenship.`,
      },
    ],
    image:
      "https://saracenssolicitors.co.uk/wp-content/uploads/2023/04/uk-immigration-saracens-solicitors.jpg",
  },
  {
    url: "banking-finance",
    title: "Banking & Finance.",
    sections: [
      {
        subtitle: "Loan Agreement & Debt Finance",
        text: "We provide bespoke legal advice on all types of loan agreements and debt financing arrangements. We also negotiate and draft bespoke loan and security agreements tailored to your business and your requirements ensuring you’re protected from the outset. Our experts will assist you at every step to ensure a smooth and secure process.",
      },
      {
        subtitle: "Project & Asset Based Finance",
        text: "Whether you require finance to purchase a high-value asset or need cash to develop your latest venture, or if you are lending on asset-backed securities, we can offer expert advice on the legal implications of project and asset based finance as well as draft bespoke agreements for you.",
      },
      {
        subtitle: "Restructuring",
        text: "Restructuring is one of the ways in which an organisation can turn financial distress into a profitable and successful business. Our team will advice on how exactly to restructure your particular business, the combination of experts to consult, compliance with directors’ duties, options relating to debt & equity conversions and catering for disputes resolutions early.",
      },
      {
        subtitle: "Shari’ah Compliant Finance",
        text: "We have expertise with Shari’ah compliant facilities like Mudaraba (profit sharing), Musharaka (joint venture), Murabaha (lending at cost plus profit), and Wakala (agency arrangement) products. Our team can advise both lenders and borrowers with straightforward, client-focused legal advice in relation to Shari’ah compliant financing arrangements.",
      },
    ],
    image:
      "https://saracenssolicitors.co.uk/wp-content/uploads/2023/04/banking-finance-sarancens-solicitors.jpg",
  },
  {
    url: "blockchain-digital-assets",
    title: "Blockchain & Digital Assets.",
    sections: [
      {
        subtitle: "Legal Opinion",
        text: `Before you list a token on a regulated crypto exchange you are often required to seek a legal opinion. The letter is used to verify that the token has undergone technical analysis and that any associated documentation and material has also been classified.

        As experts in this field, We can conduct a thorough analysis of the structure and features of the token, along with the details of the project and proposed sale to ensure it meets legal requirements and the right level of protection.`,
      },
      {
        subtitle: "Smart Contracts",
        text: `Smart contracts are self-executing digital contracts that contain all the clauses within the code, offering a cost-effective method of reducing overheads and ensuring processes are more streamlined.

        In some cases these types of contracts are easily created and easy to access, while also reducing the risk of fraud. However, like all contracts they require legal analysis and need to be constructed in a way that offers protection for all relevant parties.
        
        We ensure that the executed outcome of your smart contract is enforceable and legally robust, offering insightful tech guidance to help you achieve your commercial aims.`,
      },
      {
        subtitle: "Tax & Regulation",
        text: `Managing your taxes can be complicated and stressful at the best of times, and if you trade in cryptocurrency, you may be unsure whether it should be declared. HMRC have stated that profits made on cryptocurrency investments are liable for taxation, so whether you are an individual or a business, this is something that now needs to be considered.

        We can discuss your options and process any tax disclosures you intend to make to HMRC. Our finance solicitors will help your asset remain as tax efficient as possible, while always ensuring you are compliant.`,
      },
      {
        subtitle: "NFTs & Intellectual Property",
        text: `We are renowned for our expertise in Intellectual Property (IP) and have seen a steady growth of similar issues now arising in the world of Non-Fungible Tokens (NFTs).

        Our team can provide specialist advice and guidance for third parties that wish to protect the IP rights of their NFTs and can also help with any IP dispute that has arisen out of the sale, purchase or creation of an NFT.`,
      },
    ],
    image:
      "https://saracenssolicitors.co.uk/wp-content/uploads/2023/04/blockchain-currency-saracens-solicitors.jpg",
  },
  {
    url: "commercial-litigation",
    title: "Commercial Litigation.",
    sections: [
      {
        subtitle: "Disclaimer",
        text: `We typically advise and act for clients with claims in excess of £15,000.00. Based on your circumstances, we will be able to provide you a consultation or advise on the next steps available to you. Please leave an enquiry with us and we will get back to you.`,
      },
      {
        subtitle: "Dispute Resolution & Mediation",
        text: `Litigation should always be considered as a last resort. Our team here however understand that court proceedings are at times necessary In the event of a dispute, we are experts at identifying the most appropriate method of dispute resolution and achieving the best outcome for your business needs.`,
      },
      {
        subtitle: "Debt Recovery",
        text: `Our experts can act on behalf of your business to recover debts quickly and efficiently as, using a range of different options. We also have the expertise to defend clients who are being unlawfully pursued or pressurised into paying debts that are simply not owed.`,
      },
      {
        subtitle: "Commercial Insolvency",
        text: `In the event of a commercial insolvency, we can offer advice on a variety of options available to businesses including, but not limited to: Administration, Liquidation, Company voluntary arrangements, Receivership. We also have the expertise to offer advice and guidance to creditors of an insolvent party.`,
      },
      {
        subtitle: "Commercial Property",
        text: `We consider both the commercial and financial implications of the possible actions available to you and provide specialist advice and representation to Freeholders, Leaseholders, Landlords and Tenants in commercial property disputes including, but not limited to: Adverse Possession Claims, Forfeiture, Landlord and Tenant Disputes.`,
      },
      {
        subtitle: "Media & Intellectual Property",
        text: `Whether you have a dispute or a great new idea our expert advice will ensure you are properly protected. Our team are experienced on all matters of Copyright, Data Protection, Patents, Regulation of Media, Trademarks, Reputation Management, Design Rights`,
      },
    ],
    image:
      "https://saracenssolicitors.co.uk/wp-content/uploads/2023/04/commercial-litigation-saracens-solicitors.jpg",
  },
  {
    url: "commercial-property",
    title: "Commercial Property.",
    sections: [
      {
        subtitle: "Sale/Purchase of Commercial Premise",
        text: `When looking at acquiring or selling a commercial property, it is important to have the backing of an experienced property solicitor. Our property team has over 50 years’ experience. Awarded specialist Law Society accreditation in the field of property law and nominated last year for conveyancer of the year (Sunday Times EEA Awards). Regardless of the value or complications we can help you through the transaction process.`,
      },
      {
        subtitle: "Commercial Leases / Business Tenancy",
        text: `Our specialist commercial property team provides support and guidance for both landlords and tenants, on varying aspects of commercial property. A lease may be a 5, 10, even 20+ year commitment and so great skill and care is needed when negotiating. Our property lawyers have the depth of experience to guide you through.`,
      },
      {
        subtitle: "Planning / Section 106",
        text: `Planning legislation in England is extensive and for an untrained eye, can appear very complicated. The team at Saracens is adept and handling a range of matters from smaller planning applications right through to larger development schemes including section 106 agreements. We can assist you, advising on the most effective approach from the outset.`,
      },
    ],
    image:
      "https://saracenssolicitors.co.uk/wp-content/uploads/2023/04/commercial-property-saracns.jpg",
  },
];
