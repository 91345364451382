import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";

// images
import testmonial from "../../images/testimonial/1.png";
import testmonial2 from "../../images/testimonial/2.png";

const sliders = [
  {
    text: "“We instructed our team in a sensitive matter in the hope that it would be resolved without too much hassle. The service we received was excellent and despite the opposing party being troublesome and slippery, we were quickly advised on the strengths and weaknesses of our case. I would be more than happy to recommend us to friends and family.”",
    images: testmonial2,
    title: "Bob Paxman",
    subTitle: "Chief Executive, Talking2Minds",
  },
  {
    text: "“I had a dispute with my insurers as they were refusing to cover me for a claim. I was really stressed about the situation and worried about finding the right solicitor to help deal with the problem. Thankfully we stepped in and even helped recover my legal fees. I was very happy with the service they provided and was given re-assurance throughout the case. Highly recommended and brilliant service.”",
    images: testmonial2,
    title: "Mr W Ahmed",
    subTitle: "Senior Manager at Arab Banking Corporation Plc",
  },
  {
    text: "“I recently instructed the team in an employment matter, as a returning client having used the firm twice before. I received an excellent service, just as I had expected. I was advised of what my options were as well as matters that went above and beyond that in practical terms, which I didn’t expect. I found their common sense approach refreshing and I would definitely instruct them again and will be recommending them to family and friends.”",
    images: testmonial2,
    title: "Mr M Amin",
    subTitle: "",
  },
];
const settings = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};
const Testmonial = ({ className }) => {
  return (
    <div className={className}>
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-4 col-md-6">
            <div className="testimonialImages">
              <img src={testmonial} alt="" />
            </div>
          </div> */}
          <div className="col-lg-12 col-md-12">
            <div
              className="testimonialContent"
              style={{
                marginBottom: "150px",
              }}
            >
              <span>What People Say About Us</span>
              <h2>Testimonials</h2>
              <Slider className="testmonialSlider" {...settings}>
                {sliders.map((slider, i) => (
                  <div key={i} className="slideItem">
                    <p>{slider.text}</p>
                    <div className="thumbWrap">
                      {/* <div className="thumbImg">
                        <img src={slider.images} alt="" />
                      </div> */}
                      <div className="imgContent">
                        <h4>{slider.title}</h4>
                        <span>{slider.subTitle}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Testmonial;
