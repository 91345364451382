import React, { Fragment, useEffect, useMemo, useState } from "react";
import HeaderBotton from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import Breadcumb from "../../../components/Breadcumb";
import NewsLetter from "../../../components/Newsletter";
import CetagorySidebar from "../../../components/CetagorySidebar";
import FooterArea from "../../../components/FooterArea";
import BannerSidebar from "../../../components/BannerSidebar";
import SingleContentArea from "../../../components/SingleContentArea";

// images
import breadcumb from "../../../images/breadcumb/1.jpg";
import banner from "../../../images/practice/2.jpg";
import single from "../../../images/practice/3.jpg";

import "./style.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PRACTICES } from "../../../utils/helpers";

const PracticeSinglePage = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  console.log("location", location);
  console.log("params", params);
  const [selectedPractice, setSelectedPractice] = useState(null);

  const breadcumbMenu = useMemo(
    () => [
      { name: "Home", route: "/" },
      { name: "Practices", route: "/practice" },
      { name: selectedPractice?.title || "" },
    ],
    [selectedPractice]
  );

  useEffect(() => {
    if (!params?.practice) {
      navigate("/");
    } else {
      const newPractice = PRACTICES.find((p) => p.url === params.practice);
      if (newPractice) {
        setSelectedPractice(newPractice);
      } else {
        navigate("/");
      }
    }
  }, [params, navigate]);

  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
      </header>
      <Breadcumb
        className="breadcumbArea"
        title={selectedPractice?.title || ""}
        breadcumbMenu={breadcumbMenu}
        background={selectedPractice?.image || breadcumb}
      />
      <div className="singleArea">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <aside className=" pr25">
                {/* <BannerSidebar image={banner} className="bannerWrapper" /> */}
                <CetagorySidebar
                  title="Our Practices"
                  className="cetagoryWrap"
                />
              </aside>
            </div>
            <div className="col-lg-8">
              <SingleContentArea
                className="singleContentArea"
                // image={single}
                // avatar={true}
                selectedPractice={selectedPractice}
              />
            </div>
          </div>
        </div>
      </div>
      <NewsLetter className="newsLetterArea" />
      <FooterArea />
    </Fragment>
  );
};
export default PracticeSinglePage;
