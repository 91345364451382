import React, { Fragment, useMemo } from "react";
import HeaderBotton from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import HeroSlider from "../../../components/HeroSlider";
import Service from "../../../components/Service";
import About from "../../../components/About";
import ServiceArea from "../../../components/ServiceArea";
import Portfolio from "../../../components/Portfolio";
import Testmonial from "../../../components/Testmonial";
import ContactArea from "../../../components/ContactArea";
import TeamMember from "../../../components/TeamMember";
import CounterArea from "../../../components/CounterArea";
// import BlogArea from "../../../components/BlogArea";
import NewsLetter from "../../../components/Newsletter";
import FooterArea from "../../../components/FooterArea";
// images
import about from "../../../images/about/2.jpg";
import signature from "../../../images/about/1.png";

// images
import portfolio1 from "../../../images/studies/1.jpg";
import portfolio2 from "../../../images/studies/2.jpg";
import portfolio3 from "../../../images/studies/3.jpg";
import portfolio4 from "../../../images/studies/4.jpg";
import portfolio5 from "../../../images/studies/5.jpg";
import { PRACTICES } from "../../../utils/helpers";

const aboutText = [
  {
    text: "We specialise in commercial law, real estate and property, commercial and general litigation, personal injury, employment, family, insolvency, business and private immigration. We are also one of the foremost English law firms providing Shari’ah compliant services alongside regular client services.",
  },
  {
    text: "Our clients include individuals, families, start-up businesses, retailers, private and public companies, partnerships, asset managers, hospitality chains, telecommunications companies, educational institutes, off-shore companies, diplomats and embassies, Middle Eastern royalty, celebrities from the worlds of beauty and business, charities and not for profit organisations.",
  },
];

const heroSliders = [
  {
    images: "slideWrapperOne",
    title: "Building Trust Through Understanding.",
    subTitle: "As Like A Friend.",
    text: "",
    button: "Contact us now",
    imageUrl:
      "https://saracenssolicitors.co.uk/wp-content/uploads/2020/08/saracens-solicitors-london-banner.jpg",
  },
  {
    images: "slideWrapperTwo",
    title: "Building Trust Through Communication.",
    subTitle: "As Like A Friend.",
    text: "",
    button: "Contact us now",
    imageUrl:
      "https://saracenssolicitors.co.uk/wp-content/uploads/2023/03/principles-values-saracenssolicitors.jpg",
  },
];

const services = [
  {
    icon: "flaticon-parents",
    title: "Family Law",
    content: "",
  },
  {
    icon: "flaticon-wounded",
    title: "Personal Injury",
    content: "",
  },
  {
    icon: "flaticon-employee",
    title: "Business Law",
    content: "",
  },
  {
    icon: "flaticon-thief",
    title: "Criminal Law",
    content: "",
  },
  {
    icon: "flaticon-university-graduate-hat",
    title: "Education Law",
    content: "",
  },
  {
    icon: "flaticon-house",
    title: "Real Estate Law",
    content: "",
  },
];

const portfolioItem = [
  { images: portfolio1, title: "General Service", subtitle: "Corporate" },
  { images: portfolio2, title: "Personal Issue", subtitle: "General" },
  { images: portfolio3, title: "Business Accounting", subtitle: "Business" },
  { images: portfolio4, title: "Accounting issue", subtitle: "Criminal" },
  {
    images: portfolio5,
    title: "Business Accounting",
    subtitle: "Family Issue",
  },
];

const HomePageOne = () => {
  const ss = useMemo(
    () =>
      PRACTICES.map((p) => ({
        icon: "flaticon-employee",
        title: p.title,
        content: "",
        url: `/practice-details/${p.url}`,
      })),
    []
  );

  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBotton className="headerBottomArea" />
      </header>
      <HeroSlider sliders={heroSliders} className="heroSliderArea" />
      <Service className="serviceArea" />
      <About
        className="aboutArea"
        title="About Us"
        images={about}
        signature={signature}
        pragraphs={aboutText}
      />
      <ServiceArea
        className="ourServiceArea"
        title="How Can We Help You"
        subTitle="Our Practices"
        services={ss}
      />
      {/* <Portfolio
        className="portfolioArea"
        title="Our Resent Case Studies"
        subTitle="Here Our Best Work"
        portfolioItem={portfolioItem}
      /> */}
      <Testmonial className="testmonialArea" />
      <ContactArea className="contactArea" />
      <TeamMember
        title="Qualified Attorneys "
        subTitle="Meet Our Experts"
        className="teamArea"
        slider={true}
      />
      <CounterArea className="counterArea" />
      {/* <BlogArea
        className="blogArea"
        title="Latest News"
        subTitle="From Our Blog
                "
      /> */}
      <NewsLetter className="newsLetterArea" />
      <FooterArea />
    </Fragment>
  );
};
export default HomePageOne;
