import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo/logo.png";
import { ADDRESS, PHONE, PRACTICES } from "../../utils/helpers";
import "./style.scss";
const footerLinks = [
  {
    title: "Quick Link",
    menus: [
      { name: "Home", route: "/" },
      { name: "Our Practices", route: "practice" },
      { name: "Our Team", route: "attorneys" },
      // { name: "Recent Case", route: "case" },
      // { name: "Our Blog", route: "blog" },
    ],
  },
  {
    title: "Practice Area",
    menus: PRACTICES.map((p) => ({
      name: p.title,
      route: `practice-details/${p.url}`,
    })),
  },
  {
    title: "Contact Us",
    menus: [
      { name: "Head Office Address" },
      { name: ADDRESS },
      { name: `Phone: ${PHONE}` },
      { name: "Email: info@ajrsolicitors.com" },
    ],
  },
];

const FooterArea = () => {
  return (
    <footer className="footerArea">
      <div className="footerTopArea">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footerLogo">
                <Link to="/">
                  <h1
                    style={{
                      color: "#BFB595",
                    }}
                  >
                    AJR Solicitors
                  </h1>
                </Link>
                {/* <p>Contact our award-winning team.</p> */}
              </div>
            </div>
            {footerLinks.map((menu, i) => (
              <div key={i} className="col-lg-3 col-md-6 col-sm-6">
                <div className="footerWrap">
                  <h3>{menu.title}</h3>
                  <ul>
                    {menu.menus.map((item, i) => (
                      <li key={i}>
                        {item.route ? (
                          <Link to={`/${item.route}`}>{item.name}</Link>
                        ) : (
                          `${item.name}`
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="footerBottomArea">
        <div className="container">
          <div className="footerBottomContent">
            <div className="row">
              <div className="col-md-8 col-sm-10 col-12">
                <span>
                  Privacy Policy | © {new Date().getFullYear()} AJR Solicitors.
                  All rights reserved
                </span>
              </div>
              <div className="col-md-4 col-sm-2 col-12">
                {/* <ul className="socialListFooter">
                  <li>
                    <a href="#">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default FooterArea;
