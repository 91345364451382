import React from "react";
import avatarImg from "../../images/practice/4.jpg";

import "./style.scss";

const listItem = [
  "The master-builder of human happiness.",
  "Occasionally circumstances occur in which toil and pain",
  "Avoids pleasure itself, because it is pleasure",
  "who do not know how to pursue pleasure",
  "To take a trivial example, which of us ever undertakes",
];
const SingleContentArea = ({ className, avatar, image, selectedPractice }) => {
  return (
    <div className={className}>
      {/* <div className="singleContentImg">
                <img src={image} alt="" />
            </div> */}
      <div className="singleContentWrap">
        <h3>{selectedPractice?.title || ""}</h3>
        {(selectedPractice?.sections || []).map((s) => (
          <>
            {s?.subtitle && <h5>{s?.subtitle}</h5>}
            <p>{s?.text}</p>
          </>
        ))}
        {avatar && (
          <div className="avatarWra">
            <div className="row">
              <div className="col-md-4">
                <img src={avatarImg} alt="" />
              </div>
              <div className="col-md-8">
                <h4>Family Law Organizations</h4>
                <ul>
                  {listItem.map((list) => (
                    <li key={list}>{list}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default SingleContentArea;
