import React, { Fragment } from "react";
import HeaderBotton from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import Breadcumb from "../../../components/Breadcumb";
import Service from "../../../components/Service";
import About from "../../../components/About";
import ServiceArea from "../../../components/ServiceArea";
import Testmonial from "../../../components/Testmonial";
import TeamMember from "../../../components/TeamMember";
import CounterArea from "../../../components/CounterArea";
import BlogArea from "../../../components/BlogArea";
import NewsLetter from "../../../components/Newsletter";
import FooterArea from "../../../components/FooterArea";
// images
import about from "../../../images/about/2.jpg";
import breadcumb from "../../../images/breadcumb/1.jpg";

import "./style.scss";

const aboutText = [
  {
    text: "We specialise in commercial law, real estate and property, commercial and general litigation, personal injury, employment, family, insolvency, business and private immigration. We are also one of the foremost English law firms providing Shari’ah compliant services alongside regular client services.",
  },
  {
    text: "Our clients include individuals, families, start-up businesses, retailers, private and public companies, partnerships, asset managers, hospitality chains, telecommunications companies, educational institutes, off-shore companies, diplomats and embassies, Middle Eastern royalty, celebrities from the worlds of beauty and business, charities and not for profit organisations.",
  },
  {
    text: "Since our inception in 2010 we have grown from strength to strength through the expertise of our teams of specialists and the sheer variety of clients we service. We believe that what really differentiates us is our working style. We work with you as well as for you – ours is a collaborative approach to working in partnership with our clients to achieve your goals.",
  },
];

const services = [
  {
    icon: "flaticon-parents",
    title: "Family Law",
    content: "",
  },
  {
    icon: "flaticon-wounded",
    title: "Personal Injury",
    content: "",
  },
  {
    icon: "flaticon-employee",
    title: "Business Law",
    content: "",
  },
  {
    icon: "flaticon-thief",
    title: "Criminal Law",
    content: "",
  },
  {
    icon: "flaticon-university-graduate-hat",
    title: "Education Law",
    content: "",
  },
  {
    icon: "flaticon-house",
    title: "Real Estate Law",
    content: "",
  },
];

const breadcumbMenu = [{ name: "Home", route: "/" }, { name: "About us" }];

const AboutPage = () => {
  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
      </header>
      <Breadcumb
        className="breadcumbArea"
        title="About Us"
        breadcumbMenu={breadcumbMenu}
        background={
          "https://saracenssolicitors.co.uk/wp-content/uploads/2023/03/about-us-saracenssolicitors.jpg"
        }
      />
      <Service className="serviceArea mt-0" />
      <About
        className="aboutArea aboutAreaStyleTwo"
        title="Overview"
        subTitle=""
        noBtn={true}
        images={about}
        // orderLast="order-last"
        // videoId="XxVg_s8xAms"
        pragraphs={aboutText}
      />
      <ServiceArea
        className="ourServiceArea ourServiceAreaStyleTwo"
        title="How Can We Help You"
        subTitle="Area Of Practice"
        services={services}
      />
      <Testmonial className="testmonialArea pt100" />
      <CounterArea
        fullWidth={true}
        className="counterArea counterAreaStyleTwo"
      />
      <TeamMember
        title="Qualified Attorneys "
        subTitle="Meet Our Experts"
        className="teamArea"
        slider={true}
      />
      {/* <BlogArea
        className="blogArea"
        title="Latest News"
        subTitle="From Our Blog
                "
      /> */}
      <NewsLetter className="newsLetterArea" />
      <FooterArea />
    </Fragment>
  );
};
export default AboutPage;
