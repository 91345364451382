import React, { Fragment } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SectionTitle from "../Title";
import "./style.scss";

// images
// import teamMember1 from "../../images/expert/1.jpg";
// import teamMember2 from "../../images/expert/2.jpg";
// import teamMember3 from "../../images/expert/3.jpg";
// import teamMember4 from "../../images/expert/4.jpg";
// import teamMember5 from "../../images/expert/5.jpg";
// import teamMember6 from "../../images/expert/6.jpg";

const teams = [
  {
    name: "Nigel Griffiths",
    level: "CEO",
    image: "https://ajrsolicitors.com/team-images/nigel.jpg",
    socialMedia: [],
  },
  {
    name: "Bryan Gilbert POULTNEY",
    level: "SENIOR PARTNER",
    image: "https://ajrsolicitors.com/team-images/bryan.jpg",
    socialMedia: [],
  },
  {
    name: "Annemarie WILDE",
    level: "PARTNER",
    image: "https://ajrsolicitors.com/team-images/annemarie.jpg",
    socialMedia: [],
  },
  {
    name: "Richard Robinson",
    level: "SENIOR CONSULTANT",
    image: "https://ajrsolicitors.com/team-images/r-r.jpg",
    socialMedia: [],
  },
  {
    name: "Rahmat Raghwani",
    level: "CONSULTANT",
    image: "https://ajrsolicitors.com/team-images/rahmart.webp",
    socialMedia: [],
  },
  {
    name: "Catherine James",
    level: "SENIOR CONSULTANT",
    image: "https://ajrsolicitors.com/team-images/catherine.png",
    socialMedia: [],
  },
  {
    name: "Claire Jenkins",
    level: "SENIOR CONSULTANT",
    image: "https://ajrsolicitors.com/team-images/claire.jpg",
    socialMedia: [],
  },
  {
    name: "Fatima Bala",
    level: "BUSINESS OPERATIONS & STRATEGY",
    image: "https://ajrsolicitors.com/team-images/fatima.jpg",
    socialMedia: [],
  },
  // {
  //   name: "Kay Karanky",
  //   level: "PARTNER",
  //   image: "https://ajrsolicitors.com/team-images/kay-karanky-real.png",
  //   socialMedia: [],
  // },
  {
    name: "Fathia Pati",
    level: "PARALEGAL",
    image: "https://ajrsolicitors.com/team-images/fathia-2.jpg",
    socialMedia: [],
  },
  // {
  //   name: "Fozia Kiyani",
  //   level: "HEAD OF PEOPLE",
  //   image:
  //     "https://saracenssolicitors.co.uk/wp-content/uploads/2020/12/blank-silhouette-1-600x663.png",
  //   socialMedia: [],
  // },
  // {
  //   name: "Fraz Butt",
  //   level: "SENIOR PARTNER",
  //   image:
  //     "https://saracenssolicitors.co.uk/wp-content/uploads/2020/04/fraz-butt-600x663.png",
  //   socialMedia: [],
  // },
  // {
  //   name: "Humairaa Khalik",
  //   level: "CONSULTANT",
  //   image:
  //     "https://saracenssolicitors.co.uk/wp-content/uploads/2020/04/humairaa-khalik-600x663.png",
  //   socialMedia: [],
  // },
  // {
  //   name: "Jessica Ng",
  //   level: "RECEPTION AND ADMIN ASSISTANT",
  //   image:
  //     "https://saracenssolicitors.co.uk/wp-content/uploads/2022/08/jessica-ng-600x663.png",
  //   socialMedia: [],
  // },
  // {
  //   name: "Judith Logue",
  //   level: "HR ADMIN ASSISTANT",
  //   image:
  //     "https://saracenssolicitors.co.uk/wp-content/uploads/2022/01/judith-logue-600x663.png",
  //   socialMedia: [],
  // },
  // {
  //   name: "Kristina Tramontin",
  //   level: "CONVEYANCING LEGAL SECRETARY",
  //   image:
  //     "https://saracenssolicitors.co.uk/wp-content/uploads/2022/01/kristina-tramontin-600x663.png",
  //   socialMedia: [],
  // },
  // {
  //   name: "Kunal Patel",
  //   level: "SENIOR CONSULTANT",
  //   image:
  //     "https://saracenssolicitors.co.uk/wp-content/uploads/2020/04/kunal-patel-600x663.png",
  //   socialMedia: [],
  // },
  // {
  //   name: "Laura Walsh",
  //   level: "SENIOR CONSULTANT",
  //   image:
  //     "https://saracenssolicitors.co.uk/wp-content/uploads/2021/01/laura-walsh-600x663.png",
  //   socialMedia: [],
  // },
  // {
  //   name: "Lee Grantina",
  //   level: "ADMINISTRATION MANAGER",
  //   image:
  //     "https://saracenssolicitors.co.uk/wp-content/uploads/2021/01/lee-grantina-600x663.png",
  //   socialMedia: [],
  // },
  // {
  //   name: "Mandeep Kaur",
  //   level: "CONSULTANT",
  //   image:
  //     "https://saracenssolicitors.co.uk/wp-content/uploads/2021/11/mandeep-kaur-600x663.png",
  //   socialMedia: [],
  // },
  // {
  //   name: "Marcia Howards",
  //   level: "SENIOR ASSOCIATE",
  //   image:
  //     "https://saracenssolicitors.co.uk/wp-content/uploads/2022/05/marcia-howards-600x663.png",
  //   socialMedia: [],
  // },
  // {
  //   name: "Michael Michael",
  //   level: "ACCOUNTS ASSISTANT",
  //   image:
  //     "https://saracenssolicitors.co.uk/wp-content/uploads/2022/06/michael-michael-600x663.png",
  //   socialMedia: [],
  // },
  // {
  //   name: "Minal Popat",
  //   level: "SENIOR CONSULTANT",
  //   image:
  //     "https://saracenssolicitors.co.uk/wp-content/uploads/2020/04/minal-popat-600x663.png",
  //   socialMedia: [],
  // },
  // {
  //   name: "Nishtar Saleem",
  //   level: "SENIOR PARTNER",
  //   image:
  //     "https://saracenssolicitors.co.uk/wp-content/uploads/2020/04/nishtar-saleem-600x663.png",
  //   socialMedia: [],
  // },
  // {
  //   name: "Noor Fatema",
  //   level: "CONVEYANCING ADMINISTRATOR",
  //   image:
  //     "https://saracenssolicitors.co.uk/wp-content/uploads/2023/04/noor-fatima-600x663.png",
  //   socialMedia: [],
  // },
  // {
  //   name: "Sadek Mangoo",
  //   level: "HEAD OF FINANCE",
  //   image:
  //     "https://saracenssolicitors.co.uk/wp-content/uploads/2020/09/sadeq-mangoo-600x663.png",
  //   socialMedia: [],
  // },
  // {
  //   name: "Sally Nguyen",
  //   level: "IMMIGRATION CONSULTANT",
  //   image:
  //     "https://saracenssolicitors.co.uk/wp-content/uploads/2021/02/sally-nguyen-600x663.png",
  //   socialMedia: [],
  // },
  // {
  //   name: "Sophie Chapman",
  //   level: "ASSOCIATE",
  //   image:
  //     "https://saracenssolicitors.co.uk/wp-content/uploads/2021/06/sophie-chapman-600x663.png",
  //   socialMedia: [],
  // },
  // {
  //   name: "Soumaya Eddridi",
  //   level: "ASSOCIATE",
  //   image:
  //     "https://saracenssolicitors.co.uk/wp-content/uploads/2020/09/soumaya-eddridi-600x663.png",
  //   socialMedia: [],
  // },
  // {
  //   name: "Syed Khalifa",
  //   level: "SOCIAL MEDIA & MARKETING EXECUTIVE",
  //   image:
  //     "https://saracenssolicitors.co.uk/wp-content/uploads/2020/03/syed-khalifa-600x663.png",
  //   socialMedia: [],
  // },
  // {
  //   name: "Taiba Iqbal",
  //   level: "ASSOCIATE",
  //   image:
  //     "https://saracenssolicitors.co.uk/wp-content/uploads/2022/09/taiba-iqbal-600x663.png",
  //   socialMedia: [],
  // },
  // {
  //   name: "Victoria Norman",
  //   level: "ADMINISTRATIVE ASSISTANT",
  //   image:
  //     "https://saracenssolicitors.co.uk/wp-content/uploads/2021/11/victoria-norman-600x663.png",
  //   socialMedia: [],
  // },
  // {
  //   name: "Zama Razaq",
  //   level: "ASSOCIATE",
  //   image:
  //     "https://saracenssolicitors.co.uk/wp-content/uploads/2020/09/zama-razaq-600x663.png",
  //   socialMedia: [],
  // },
];

const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const TeamMember = ({ className, title, subTitle, slider, noGutters }) => {
  return (
    <div className={className}>
      <div className="container">
        <div className={!noGutters ? "row" : "row no-gutters"}>
          <div className="col-12">
            <SectionTitle title={title} subTitle={subTitle} />
          </div>
          {slider ? (
            <div className="col-12">
              <Slider className="teamSlideArea" {...settings}>
                {teams.map((team, i) => (
                  <div key={i} className="teamWrapper">
                    <div className="teamImage">
                      <div
                        style={{
                          backgroundSize: "cover",
                          backgroundPosition: "top",
                          backgroundImage: `url(${team.image})`,
                          width: "100%",
                          height: "300px",
                        }}
                      ></div>
                      {/* <img src={team.image} alt="" /> */}
                    </div>
                    <div className="teamContent">
                      <h3>{team.name}</h3>
                      <span>{team.level}</span>
                      <ul>
                        {team.socialMedia.map((social) => (
                          <li key={social}>
                            <a href="#">
                              <i
                                className={`fa fa-${social}`}
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          ) : (
            <Fragment>
              {teams.map((team, i) => (
                <div key={i} className="col-lg-4 col-md-6 col-12">
                  <div className="teamWrapper">
                    <div className="teamImage">
                      <div
                        style={{
                          backgroundSize: "cover",
                          backgroundPosition: "top",
                          backgroundImage: `url(${team.image})`,
                          width: "100%",
                          height: "300px",
                        }}
                      ></div>
                      {/* <img src={team.image} alt="" /> */}
                    </div>
                    <div className="teamContent">
                      <h3>{team.name}</h3>
                      <span>{team.level}</span>
                      <ul>
                        {team.socialMedia.map((social) => (
                          <li key={social}>
                            <a href="#">
                              <i
                                className={`fa fa-${social}`}
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};
export default TeamMember;
